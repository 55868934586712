.sticky-header.sticky {
	position: fixed;
	top: 0;
	left: 0;
	width: 100%;
	background: $white;
	display: block;
	-webkit-box-shadow: 0px 10px 20px 0px rgba(8, 0, 42, 0.08);
	-moz-box-shadow: 0px 10px 20px 0px rgba(8, 0, 42, 0.08);
	box-shadow: 0px 10px 20px 0px rgba(8, 0, 42, 0.08);

	button {
		padding: 0px 15px !important;
	}

	.header-main-wrapper {
		@media #{$xs,$sm,$md,$lg} {
			padding: 4px 0 !important;
		}
	}
}
.header-main3.sticky {
	border: none;
}
.header-main-wrapper {
	max-width: 1720px;
	margin: 0 auto;
	@media #{$xs,$sm,$md,$lg} {
		padding: 16px 0;
	}
}
.transpaerent-header {
	position: absolute;
	right: 0;
	left: 0;
	z-index: 999;
}

.main-menu {
	ul {
		padding-left: 0px !important;

		li {
			display: inline-block;
			margin: 0px 29px;
			position: relative;
			@media #{$xxl} {
				margin: 0px 16px;
			}
			@media #{$lg} {
				margin: 0px 20px;
			}
			a {
				font-size: 16px;
				color: $heading-color;
				display: block;
				padding: 42px 0px;
				font-weight: 600;
				text-transform: capitalize;
				line-height: 1;
				padding-right: 8px;
			}

			.sub-menu {
				background: $white none repeat scroll 0 0;
				box-shadow: 0 6px 12px rgba(0, 0, 0, 0.176);
				left: 0;
				opacity: 0;
				position: absolute;
				top: 120%;
				transition: all 0.3s ease 0s;
				visibility: hidden;
				width: 240px;
				z-index: 9;
				border-top: 3px solid $theme-color;
				text-align: left;
				padding: 15px 0;
				li {
					display: block;
					margin: 0px;
					padding: 8px 25px 8px 25px;
					&:hover {
						> .sub-menu {
							top: 0%;
						}
					}
					.sub-menu {
						left: 100%;
						top: 20%;
						@include transition(0.3s);
						width: 190px;
					}
					a {
						padding: 0px;
						display: inline-block;
						color: #212237;
						position: relative;
						cursor: pointer;
						&:hover {
							color: $theme-color;
						}
					}
					a {
						&:before {
							width: 100%;
							left: 0;
							right: auto;
						}
					}
				}
			}
			&:hover {
				a {
					color: $theme-color;
				}
				&.menu-item-has-children::after {
					color: $theme-color;
				}
				> .sub-menu {
					opacity: 1;
					visibility: visible;
					top: 100%;
				}
			}
			&.menu-item-has-children {
				position: relative;
				fa-icon {
					color: $white;
					position: absolute;
					font-size: 13px;
					top: 50%;
					transform: translateY(-50%);
					right: -22px;
					line-height: 100px;
				}
			}
			ul li.menu-item-has-children::after {
				right: 14px;
			}
		}
	}
}

.main-menu ul {
	margin-bottom: 0px;
}

.header-area-2 .main-menu ul li {
	margin: 0px 29px;
}
.header-top-area {
	background: $heading-color;
	border-bottom: 1px solid #ebebeb;
}
.header-top-inner {
	max-width: 1720px;
	margin: 0 auto;
}
.header-top-icon {
	@media #{$md} {
		padding-left: 80px;
	}
}
.header-top-icon {
	fa-icon {
		margin-right: 10px;
		color: $theme-color;
	}
	a {
		color: $white;
		position: relative;
		padding-right: 35px;
		@media #{$lg} {
			padding-right: 10px;
		}
		height: 40px;
		display: inline-block;
		line-height: 40px;
		font-size: 14px;
		font-weight: 600;
		&:hover {
			color: $theme-color;
		}
	}
	span {
		color: $white;
		font-size: 14px;
		font-weight: 600;
	}
}

.header-social {
	a {
		color: $white;
		font-size: 15px;
		margin-left: 20px;
		&:hover {
			color: $theme-secondary-color;
		}
	}
}

.header-top-icon a::before {
	position: absolute;
	content: "";
	height: 100%;
	width: 1px;
	background: #ebebeb;
	top: 0px;
	right: 18px;
	@media #{$lg,md,sm} {
		display: none;
	}
}

.header-menu-top-icon {
	a {
		display: block;
		font-size: 16px;
		margin-bottom: 5px;
		&:hover {
			color: $theme-color;
		}
	}
	fa-icon {
		margin-right: 10px;
		color: $theme-color;
	}
}

.header-main3 .main-menu {
	ul {
		li {
			margin: 0px 29px;
			a {
				color: $white;
			}
		}
	}
}
.header-main3 .main-menu ul li.menu-item-has-children::after {
	color: $white;
}
.header-main3 .main-menu ul li ul li.menu-item-has-children::after {
	color: #212237;
}
.header-main3 {
	border-bottom: 0px solid #6d6d6d;
	transition: none !important;
	a {
		transition: none !important;
	}
}
.header.header-main3 {
	&:before {
		position: absolute;
		content: "";
		opacity: 0.23;
		width: 100%;
		height: 100%;
		background: $heading-color;
		z-index: -1;
	}
}
.header-main3.transpaerent-header::after {
	position: absolute;
	left: 0;
	top: 0;
	width: 100%;
	height: 100%;
	background: $heading-color;
	opacity: 0.23;
	content: "";
}
.header-main3.sticky.transpaerent-header::after {
	display: none;
}
.header-main3.transpaerent-header > * {
	position: relative;
	z-index: 9;
}
.header-main3 .main-menu {
	ul {
		li {
			a {
				color: $white;
			}
		}
	}
}
.header-main3.sticky .main-menu {
	ul {
		li {
			a {
				color: $heading-color;
				padding: 20px 10px 20px 0 !important;
			}
		}
	}
}
.header.header-main3.sticky {
	&::before {
		display: none;
	}
}
.header-main3 .logo-black {
	display: none;
}
.header-main3.sticky .logo-black {
	display: block;
}
.header-main3.sticky .logo-white {
	display: none;
}
.header-main3.sticky .main-menu ul li.menu-item-has-children {
	fa-icon {
		color: $heading-color;
	}
	.sub-menu li a {
		padding: 0px !important;
	}
}
.header-main3.sticky .header__cart-icon svg * {
	fill: $heading-color;
}

.hero-text {
	position: relative;
	z-index: 2;
}

.bar-icon {
	width: 20px;
	height: 14px;
	display: flex;
	flex-direction: column;
	justify-content: space-between;
	overflow: hidden;

	span {
		width: 100%;
		height: 2px !important;
		background: #000;
		display: inline-block;

		&:nth-of-type(2) {
			margin-left: 9px;
			transition: 0.3s;
		}
	}
}

.side-toggle.header-3 {
	padding: 17px 14px;
	display: block;
	position: relative;

	&:hover .bar-icon span {
		margin-left: 0px !important;
	}
}
.side-toggle.header-3:before {
	position: absolute;
	top: 0;
	content: "";
	background: $white;
	opacity: 0.17;
	width: 100%;
	height: 100%;
	left: 0;
}
.side-toggle.header-3 span {
	background: $white;
}

.header-main3.sticky .side-toggle.header-3 span {
	background: $heading-color;
}

.side-info {
    background: #f5f5f5;
    height: 100%;
    position: fixed;
    z-index: 1990;
    right: -100%;
    top: 0;
    width: 365px;
    padding: 20px;
    transition: .6s;
	@media #{$md,$sm,$xs} {
		width: 300px;
		padding: 15px 25px;
	}
}
.side-info.opened {
	right: 0;
}

.main-menu-lg-block {
	@media #{$lg,$md,$sm,$xs} {
		display: none;
	}
}
.cart-wrapper.mr-45 {
	@media #{$md} {
		margin-right: 5px;
	}
}
.header-logo img {
	@media #{$lg, $md, $sm, $xs} {
		max-width: 180px;
		max-height: 45px;
	}
}

.header-btn {
	display: flex;
	align-items: center;

	button {
		fa-icon {
			margin-right: 10px !important;
			@media #{$xs} {
				margin-right: 0px !important;
			}
		}
	}

	.mat-mdc-raised-button {
		background-color: $theme-color !important;
	}
}


