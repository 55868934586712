@import '../../../theme-variables.scss';
@import '../../../theme-breakpoints.scss';
@import '../../../custom-palettes.scss';

.w-img {
	& img {
		width: 100%;
	}
}
.gallery-area {
	max-width: 1920px;
	margin: 0 auto;
}
.gallery-inner {
	display: flex;
	flex-wrap: wrap;
	> div {
		width: 20%;
		@media #{$sm} {
			width: 33.33%;
		}
		@media #{$xs} {
			width: 50%;
		}
	}
}
.gallery-single {
	position: relative;
	&:hover {
		.gallery-link {
			opacity: 1;
			visibility: visible;
		}
	}
}
.gallery-link {
	position: absolute;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
	background: rgba(0, 0, 0, 0.6);
	content: "";
	transition: 0.3s;
	opacity: 0;
	visibility: hidden;
	transition: 0.3s;
}
.gallery-insta {
	position: absolute;
	top: 50%;
	left: 50%;
	transform: translate(-50%, -50%);
	color: #fff;
	font-size: 26px;
	z-index: 1;
	opacity: 1;
	&:hover {
		color: #fff;
	}
}

.m-img {
	& img {
		max-width: 100%;
	}
}
.features-mt {
	margin-top: -85px;
}


img {
	@include transition(0.3s);
}
a:focus,
.button:focus {
	text-decoration: none;
	outline: none;
}
a:focus,
a:hover {
	color: inherit;
	text-decoration: none;
}
a,
button {
	color: $body-text;
	outline: none;
	border: none;
	background: transparent;
}
button:hover {
	cursor: pointer;
}
button:focus {
	outline: 0;
	border: 0;
}
.uppercase {
	text-transform: uppercase;
}
.capitalize {
	text-transform: capitalize;
}

h1,
h2,
h3,
h4,
h5,
h6 {
	font-family: $raleway;
	color: $heading-color;
	margin-top: 0px;
	font-weight: 700;
	line-height: 1.3;
	@include transition(0.2s);
}

h1 {
	font-size: 40px;
}
h2 {
	font-size: 36px;
}
h3 {
	font-size: 24px;
}
h4 {
	font-size: 20px;
}
h5 {
	font-size: 16px;
}
h6 {
	font-size: 14px;
}
p {
	font-family: $nunito;
	font-size: 16px;
	font-weight: normal;
	color: $body-text;
	margin-bottom: 15px;
	line-height: 26px;
}

*::-moz-selection {
	background: $theme-color;
	color: $white;
	text-shadow: none;
}
::-moz-selection {
	background: $theme-color;
	color: $white;
	text-shadow: none;
}
::selection {
	background: $theme-color;
	color: $white;
	text-shadow: none;
}

/*--
    - Input Placeholder
-----------------------------------------*/
*::-moz-placeholder {
	color: $body-text;
	font-size: 14px;
	opacity: 1;
}
*::placeholder {
	color: $body-text;
	font-size: 14px;
	opacity: 1;
}

/*--
    - Common Classes
-----------------------------------------*/
.fix {
	overflow: hidden;
}
.clear {
	clear: both;
}

.f-left {
	float: left;
}
.f-right {
	float: right;
}

.z-index-1 {
	z-index: 1;
}

.z-index-11 {
	z-index: 11;
}

.overflow-y-visible {
	overflow-x: hidden;
	overflow-y: visible;
}

.p-relative {
	position: relative;
}
.p-absolute {
	position: absolute;
}
/*--
    - Background color
-----------------------------------------*/
.grey-bg {
	background: $theme-color-2;
}
.section-bg {
	background: $section-bg;
}

.pink-bg {
	background: $theme-color;
}

.white-bg {
	background: $white;
}
.black-bg {
	background: $black;
}

.footer-bg {
	background: $footer-bg;
}

// .primary-bg {
// 	background: #222;
// }

/*--
    - color
-----------------------------------------*/

.text-white h1,
.text-white h2,
.text-white h3,
.text-white h4,
.text-white h5,
.text-white h6,
.text-white p,
.text-white span,
.text-white li,
.text-white a {
	color: $white !important;
}

.white-color {
	color: $white;
}

.theme-color {
	color: $theme-color !important;
}
.black-color {
	color: $black;
}

// spacing

// overlay

.body-overlay {
	background-color: rgba($color: #000000, $alpha: 0.5);
	height: 100%;
	width: 100%;
	position: fixed;
	top: 0;
	z-index: 1004;
	left: 0;
	opacity: 0;
	visibility: hidden;
	@include transition(0.3s);

	&:hover {
		cursor: pointer;
	}
}
.body-overlay.opened {
	opacity: 1;
	visibility: visible;
}

// extra css
.progress-wrap {
	@media #{$xs} {
		right: 15px;
		bottom: 15px;
	}
}
.long-padding {
	padding-bottom: 285px;
}

.sidebar-filter {
    position: relative;
    display: flex;
    flex-direction: column;
	user-select: none;

    .filter-title {
		display: flex;
		flex-direction: row;
		justify-content: space-between;
		gap: 20px;
		cursor: pointer;
		
        h3 {
            font-size: 20px;
            font-weight: 700;
            position: relative;
            z-index: 1;
        }
		fa-icon {
			position: absolute;
			top: 0;
			right: 0;
			color: $body-text;
			cursor: pointer;
			transform: rotate(-180deg);
			transition: all 0.2s;
		}
    }

    .filter-panel {
		padding-top: 20px;
		transition: all 0.3s;
        ul {
            li {
                margin-bottom: 10px;
            }
        }
    }

    .content-hidden {
		padding-top: 0px;
        ul {
            display: none;
        }
        fa-icon {
            transform: rotate(0deg);
        }
    }
}

.course-title-breadcrumb {
	.breadcrumb-item {
		margin-right: 10px;
	}
	.breadcrumb-item + .breadcrumb-item {
		padding-left: 0;
	}
}
.mat-elevation-z5 {
	box-shadow: none;
}

.section-title {
	h2 {
		font-size: 36px;
		line-height: 1.3;
		@media #{$lg} {
			font-size: 30px;
		}
		@media #{$md} {
			font-size: 30px;
		}
		@media #{$sm} {
			font-size: 28px;
		}
		@media #{$xs} {
			font-size: 26px;
		}
		a {
			color: $theme-color;
		}
	}
}