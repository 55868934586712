// variables
$white: #ffffff;
$black: #000;

$theme-color-2: #383534;
$theme-color-2-2:#A2A2A2;

$theme-color: #6dc1c0;
$theme-color-1: #3e9e9c;
$theme-color-1-2: #dfdfdf;
$theme-secondary-color: #e47545;

$theme-success-color: #008000;
$theme-success-color-2: #54a323;
$theme-danger-color: #d13100;
$theme-info-color: #2677d3;

$theme-bg: #F6F8FB;
$body-text: #575757;
$border:#edeef2;
$heading-color: #141517;
$section-bg: #F6F8FB;
$footer-bg: #000;