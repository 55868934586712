// Font Family
$nunito: 'Nunito Sans', sans-serif;
$raleway: 'Raleway', sans-serif;
$fontawesome: "Font Awesome 5 Pro";

//backgroud 
@mixin background($position: center,$size: cover,$repeat: no-repeat) {
  background: {
      position: $position;
      repeat: $repeat;
      size: $size;
  }
}

//transition
@mixin transition($time) {
  -webkit-transition: all $time ease-out 0s;
  -moz-transition: all $time ease-out 0s;
  -ms-transition: all $time ease-out 0s;
  -o-transition: all $time ease-out 0s;
  transition: all $time ease-out 0s;
}

// transform
@mixin transform($transforms) {
  -webkit-transform: $transforms;
  -moz-transform: $transforms;
  -ms-transform: $transforms;
  transform: $transforms;
}

//border
@mixin border-radius($man) {
-webkit-border-radius: $man;
-moz-border-radius: $man;
border-radius: $man;
}

// sentence case
@mixin sentence-case() {
  text-transform: lowercase;
  &:first-letter {
      text-transform: uppercase;
  }
}

// Flexbox display
@mixin flexbox() {
display: -webkit-box;
display: -moz-box;
display: -ms-flexbox;
display: -webkit-flex;
display: flex;
}

// Box shadows
@mixin box-shadow($shadow) {
-webkit-box-shadow: $shadow;
  -moz-box-shadow: $shadow;       
      box-shadow: $shadow;
}

//before-left-top
@mixin before-left-top($width, $height, $background, $left, $top) {
  position: relative;
  &::before{
      position: absolute;
      content: '';
      width: $width;
      height: $height;
      background: $background;
      left: $left;
      top: $top;
  }	
}

//before-left-bottom
@mixin before-left-bottom($width, $height, $background, $left, $bottom) {
  position: relative;
  &::before{
      position: absolute;
      content: '';
      width: $width;
      height: $height;
      background: $background;
      left: $left;
      bottom: $bottom;
  }	
}

//before-right-top
@mixin before-right-bottom($width, $height, $background, $right, $bottom) {
  position: relative;
  &::before{
      position: absolute;
      content: '';
      width: $width;
      height: $height;
      background: $background;
      right: $right;
      bottom: $bottom;
  }	
}

//before-right-bottom
@mixin before-right-top($width, $height, $background, $right, $top) {
  position: relative;
  &::before{
      position: absolute;
      content: '';
      width: $width;
      height: $height;
      background: $background;
      right: $right;
      top: $top;
  }	
}

//after-left-top
@mixin after-left-top($width, $height, $background, $left, $top) {
  position: relative;
  &::after{
      position: absolute;
      content: '';
      width: $width;
      height: $height;
      background: $background;
      left: $left;
      top: $top;
  }	
}

//after-left-bottom
@mixin after-left-bottom($width, $height, $background, $left, $bottom) {
  position: relative;
  &::after{
      position: absolute;
      content: '';
      width: $width;
      height: $height;
      background: $background;
      left: $left;
      bottom: $bottom;
  }	
}

//after-right-top
@mixin after-right-bottom($width, $height, $background, $right, $bottom) {
  position: relative;
  &::after{
      position: absolute;
      content: '';
      width: $width;
      height: $height;
      background: $background;
      right: $right;
      bottom: $bottom;
  }	
}

//after-right-bottom
@mixin after-right-top($width, $height, $background, $right, $top) {
  position: relative;
  &::after{
      position: absolute;
      content: '';
      width: $width;
      height: $height;
      background: $background;
      right: $right;
      top: $top;
  }	
}


//spacing

/* Margin Top */

@for $i from 1 through 40 {
  .mt-#{5 * $i} {
    margin-top: 5px * $i;
  }
}

@for $i from 1 through 20 {
  .mt--#{10 * $i} {
    margin-top: -10px * $i;
  }
}

/* Margin Bottom */

@for $i from 1 through 40 {
  .mb-#{5 * $i} {
    margin-bottom: 5px * $i;
  }
}

@for $i from 1 through 20 {
  .mb--#{10 * $i} {
    margin-bottom: -10px * $i;
  }
}

/* Margin Left */

@for $i from 1 through 20 {
  .ml-#{10 * $i} {
    margin-left: 10px * $i;
  }
}

/* Margin Right */

@for $i from 1 through 20 {
  .mr-#{5 * $i} {
    margin-right: 5px * $i;
  }
}


/* Padding Top */

@for $i from 1 through 40 {
  .pt-#{5 * $i} {
    padding-top: 5px * $i;
  }
}

@for $i from 1 through 20 {
  .pt--#{10 * $i} {
    padding-top: -10px * $i;
  }
}

/* Padding Bottom */

@for $i from 1 through 40 {
  .pb-#{5 * $i} {
    padding-bottom: 5px * $i;
  }
}

@for $i from 1 through 20 {
  .pb--#{10 * $i} {
    padding-bottom: -10px * $i;
  }
}

/* Padding Left */

@for $i from 1 through 20 {
  .pl-#{5 * $i} {
    padding-left: 5px * $i;
  }
}

/* Padding Right */

@for $i from 1 through 20 {
  .pr-#{5 * $i} {
    padding-right: 5px * $i;
  }
}

//section title animation
.down-mark-line {
	position: relative;
	z-index: 2;
	display: inline-block;
	&::before {
		position: absolute;
		content: "";
		left: 0;
		bottom: 0;
		width: 100%;
		z-index: -1;
		height: 100%;
		background: url(../src/assets/img/icon/down-mark-line.webp);
		background-size: contain;
		background-repeat: no-repeat;
		background-position: bottom;
	}
}

.down-mark-line-2 {
	position: relative;
	z-index: 2;
	display: inline-block;
	&::before {
		position: absolute;
		content: "";
		left: 0;
		bottom: 8%;
		width: 100%;
		z-index: -1;
		height: 100%;
		background: url(../src/assets/img/icon/down-mark-line-2.webp);
		background-size: contain;
		background-repeat: no-repeat;
		background-position: bottom;
	}
}

.down-mark-line-3 {
	position: relative;
	z-index: 2;
	display: inline-block;
	&::before {
		position: absolute;
		content: "";
		left: 0;
		bottom: 8%;
		width: 100%;
		z-index: -1;
		height: 100%;
		background: url(../src/assets/img/icon/down-mark-line-3.webp);
		background-size: contain;
		background-repeat: no-repeat;
		background-position: bottom;
	}
}