@import "@fortawesome/fontawesome-svg-core/styles.css";
@import "../src/assets/scss/theme/common.scss";
@import "../src/assets/scss/theme/animation.scss";
@import "../src/assets/scss/theme/header.scss";
@import "../src/assets/scss/theme/breadcrumb.scss";
@import "./assets/scss/material/material.scss";

* {
	margin: 0;
	padding: 0;
	box-sizing: border-box;
	scroll-behavior: auto !important;
}

body {
	font-family: $nunito;
	font-size: 16px;
	font-weight: normal;
	color: $body-text;
	line-height: 26px;
}

.container,
.container-fluid,
.container-xxl,
.container-xl,
.container-lg,
.container-md,
.container-sm {
	padding-right: var(--bs-gutter-x, 0.938rem);
	padding-left: var(--bs-gutter-x, 0.938rem);
}

.row {
	--bs-gutter-x: 1.875rem;
}

a {
	text-decoration: none;
}

.mat-calendar-body-cell {
	padding-bottom: 15px !important;
}

.mat-input-element::placeholder {
	color: #575757;
}

input.mat-input-element {
	margin-top: 2px;
}

@-moz-document url-prefix() {
	html {
		scroll-behavior: auto !important;
	}
}

.swiper-pagination-bullet-active {
	background: $theme-color !important;
}

.filter-card-wrapper {
	width: auto;

	@media #{$lg, $md, $sm, $xs} {
		width: 100% !important;
	}

	.search-button {
		padding: 25px 10px;
		height: 56px;
		width: auto;

		fa-icon {
			font-size: 23px;
		}

		@media #{$xl} {
			margin-left: 15px;
		}

		@media #{$lg, $md, $sm, $xs} {
			width: 100% !important;
		}
	}
}

.mySwiper,
.mySwiper2,
.mySwiper3 {

	.swiper-pagination {
		position: absolute !important;
		padding: 10px;
		bottom: 0px;
		width: 100% !important;
		height: 45px !important;

		.swiper-pagination-bullet {
			width: 10px !important;
			height: 10px !important;
		}
	}

	.swiper-button-prev,
	.swiper-button-next {
		position: fixed;
		color: #e47545;
		top: 50%;
	}

	.swiper-button-next {
		right: -50px;
	}

	.swiper-button-prev {
		left: -50px;
	}

}

.mySwiper2 {
	padding-bottom: 45px;
	margin-bottom: 30px;
}

.mySwiper3 {
	padding-bottom: 45px;
	margin-bottom: 30px;
}

.cc-message {
	font-size: 14px !important;
}