@use "@angular/material" as mat;
@import "./lecto.palette.scss";
@import "../../../theme-variables.scss";
@import "../../../theme-breakpoints.scss";

@include mat.core();

$my-primary: mat.define-palette($lecto-palette-primary, 900);
$my-accent: mat.define-palette($lecto-palette-accent, 600);
$my-warn: mat.define-palette($lecto-palette-warn);

$lecto-theme: mat.define-light-theme((color: (primary: $my-primary,
				accent: $my-accent,
				warn: $my-warn,
			),
		));

@include mat.core-theme($lecto-theme);
@include mat.button-theme($lecto-theme);
@include mat.all-component-themes($lecto-theme);

.cdk-global-scrollblock {
	position: static !important;
	width: 100%;
	overflow-y: none !important;
}

.mat-mdc-raised-button {
	padding: 25px 30px !important;
	border-radius: 0px !important;
}

.mdc-notched-outline__leading,
.mdc-notched-outline__notch,
.mdc-notched-outline__trailing {
	border-color: mat.get-color-from-palette($my-primary) !important;
}

// .mat-mdc-select-placeholder {
//   color: #ffffff !important;
// }

.mat-mdc-option.mdc-list-item {
	align-items: center;
	border-bottom: 1px solid #e2eeed;
}

.mat-mdc-option.mdc-list-item:last-of-type {
	border: none !important;
}

.custom-paginator {
	width: 100%;

	.mat-mdc-paginator {
		width: 100%;
		font-size: 14px;

		.mat-mdc-paginator-container {
			flex-direction: row;
			justify-content: space-between !important;
			padding: 0px !important;

			.mat-mdc-paginator-range-label {
				padding: 0px !important;
				margin: 0px !important;
			}

			.mat-mdc-paginator-page-size {
				margin-right: 0px !important;
			}

			.mat-mdc-paginator-range-label {
				margin-right: 10px !important;
			}
		}

		.mat-mdc-text-field-wrapper.mdc-text-field--outlined .mat-mdc-form-field-infix {
			padding-top: 5px;
			padding-bottom: 3px;
		}

		.mat-mdc-text-field-wrapper {
			height: 35px !important;
		}
	}
}

.mdc-dialog__surface {
	overflow-y: unset !important;
}

.offer-item {
	.mdc-form-field>label {
		display: flex;
		flex-direction: row;
	}
}

.session-info {
	.mat-mdc-dialog-title {
		background: #6dc1c0;
		padding: 10px;

		h4 {
			color: #ffffff !important;
			font-weight: normal !important;
			padding: 10px 10px 5px 10px;
		}

		.close {
			color: #ffffff;
		}
	}

	.mdc-dialog__title::before {
		display: none !important;
	}

	.mat-mdc-dialog-content {
		padding: 20px !important;
	}
}

.remaining-days {
	text-align: center !important;
}

.mat-mdc-snack-bar-container {
	&.toast-success {
		--mdc-snackbar-container-color: #008000;
		--mat-mdc-snack-bar-button-color: #ffffff --mdc-snackbar-supporting-text-color: #ffffff;
	}

	&.toast-error {
		--mdc-snackbar-container-color: #d13100;
		--mat-mdc-snack-bar-button-color: #ffffff --mdc-snackbar-supporting-text-color: #ffffff;
	}

	&.toast-info {
		--mdc-snackbar-container-color: #006f7e;
		--mat-mdc-snack-bar-button-color: #ffffff --mdc-snackbar-supporting-text-color: #ffffff;
	}
}

.mat-mdc-tooltip-panel {
	font-size: 14px !important;
}

.filter-card-content,
.locations-wrapper {
	.mat-mdc-form-field-subscript-wrapper {
		display: none !important;
	}
}

.mat-mdc-form-field-error {
	font-size: 13px;
}

.enroll-wrapper {

	.mat-step-header {
		margin-bottom: 20px !important;
		background-color: $theme-color;

		&:hover:not([aria-disabled]) {
			background-color: $theme-color !important;
		}
	}

	.mat-vertical-content-container {
		@media #{$xs, $sm, $md} {
			margin-left: 0px !important;
		}
	}

	@media #{$xs, $sm, $md} {
		.mat-stepper-vertical-line::before {
			border-left-width: 0px !important;
		}
	}

	.mat-step-text-label {
		text-overflow: ellipsis;
		overflow: hidden;
		font-weight: bold;
		font-size: 17px;
		color: #ffffff
	}

	.mat-step-icon {
		background-color: $white;
		color: $theme-secondary-color;
	}

	.mat-stepper-previous {
		padding: 10px 20px !important;
		margin-right: 10px;
		background-color: $theme-secondary-color !important;
		color: $white !important;
	}

	.mat-stepper-next {
		padding: 10px 20px !important;
		background-color: $theme-color !important;
		color: $white !important;
	}

	.mat-mdc-raised-button[disabled][disabled] {
		background-color: $theme-color-1-2 !important;
		color: $white !important;
	}

	fa-icon {
		font-size: 12px;
	}

	.mat-step-header[aria-selected="false"] {
		pointer-events: none;
		cursor: not-allowed;
		opacity: 0.6;
	}

	.mat-step-header[aria-labelledby="valid_step"] {
		pointer-events: all !important;
		cursor: pointer !important;
		opacity: 1 !important;
	}

	.mat-step-icon-state-done {

		.mat-step-icon-content {
			position: relative;
			width: 30px;
			height: 30px;

			mat-icon {
				position: absolute;
				top: -8px;
				left: 0px;
				width: 50px;
				height: 50px;
				color: #3aa92c !important;
				font-size: 38px;
				text-shadow: 2px 0px 0px #ffffff87;
			}
		}
	}

}



// .consent-payment {
// 	height: 100%;

// 	.mdc-form-field {
// 		display: flex !important;
// 		justify-content: flex-start;
// 		align-items: flex-start;
// 		height: 100%;

// 		.mdc-checkbox {
// 			display: flex;
// 			flex-direction: column;
// 			justify-content: center;
// 		}
// 	}

// }